@import "../common/variables";

.billerContainer {
  max-width: $card-width;
}

.searchInputContainer {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  justify-content: space-between;

  >:first-child {
    margin-bottom: 0;
  }

  @media (max-width: 720px) {
    flex-direction: column;
    align-items: inherit;
  }

  @media (min-width: 721px) {
    >:first-child {
      width: 400px;
    }
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  margin-left: 1rem;
  min-height: 80px;
  align-items: center;
}

.icon {
  font-size: 4.8rem;
}

.accountDetailsWrapper {
  margin-left: 2rem;
}

.accountName {
  margin-bottom: 5px;
  font-family: $font-headings;
  color: $primary-color;
}

.accountDetails {
  margin-bottom: 0px;
}

.logoSharing {
  max-height: 100px;
  max-width: 120px;
}

.logoAccessing {
  height: 100px;
}

.logoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}

.links {
  a {
    text-decoration: none;
    color: $body-text-color;
  }
}
