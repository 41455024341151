@import "../common/variables";
.downloadWarning {
  margin: 0;
}

.hint {
  color: $hint-text-color;
}

.hintIcon{
  margin-left: 0.5rem;
}