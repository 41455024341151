@import "../../../common/variables";

.header {
  margin-bottom: 20px;
}

.progressBarContainer {
  div {
    margin-bottom: 20px;
  }

  .progressBar {
    margin-bottom: 0;
  }

}
.passwordSuggestions{
  margin-bottom: 0;
}

.formErrors {
  margin-top: 1rem;
}