.container {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
}

.centerDiv {
  width: 650px;

  @media (max-width: 650px) {
    width: 100%
  }

}