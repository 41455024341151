@import "../../common/variables";

.buttonContainer {
  margin-bottom: 2rem;
}

.links {

  @media (min-width: 1000px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    :nth-child(odd) {
      margin-right: 0.5%;
    }
    :nth-child(even) {
      margin-left: 0.5%;
    }

    a {
      width: 49%;
    }
  }

  a {
    text-decoration: none;
    color: $body-text-color;
  }
}

.billerCard {
  display: flex;
  align-items: center;
  min-height: 12rem;
  width: 100%;

  .logoContainer {
    min-width: 140px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
      height: 100px;
      max-width: 140px;
    }
  }

  .detailsContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-left: 0.75rem;

    .statusRow {
      display: flex;
      align-items: baseline;

      :first-child {
        margin-right: 0.5rem;
      }
    }
  }
}
