.connectButton:hover {
  opacity: 0.75;
}

.newInputSection {
  margin-top: 2.5rem;
}

.buttonContainer {
  display: flex;
  margin-bottom: 1.5rem;
  justify-content: space-between;
}

.helpLink {
  margin-bottom: 1rem;
}

.errorMessage {
  margin-bottom: 2rem;
}
