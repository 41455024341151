@import "../../common/variables";

.connectedInfo {
  > * {
    > :first-child {
      width: 15rem;
    }
  }
}

.connected {
  padding-top: 0;
  background-color: #dff0d8;
}

.imageButton {
  padding: 0;
  margin: 0;
  border: none;
  border-radius: 4px !important;

  &:hover, &:focus {
    opacity: 0.75;
  }

}

.helpBlock {
  margin-top: -5px !important; //to counteract the margin bottom of the label
}

