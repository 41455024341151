.pageContainer {
  display: flex;

  @media (min-width: 1400px) {
    >:nth-child(1) {
      margin-right: 1rem;
    }

    >:nth-child(2) {
      margin-left: 1rem;
    }
  }

  @media (max-width: 1400px) {
    flex-direction: column;
  }
}

.buttonBar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  :first-child {
    height: unset!important;
    display: flex;
    align-items: center;
  }

  .deregisterContainer {
    display: flex;
    align-items: baseline;

    :first-child {
      margin-right: 1rem;
      @media (max-width: 500px) {
        display: none;
      }
    }
  }
}

.billDetailsContainer{
  flex: 1;
  -ms-flex: 1 1 auto;
}

.pdfViewContainer{
  flex: 1;
  -ms-flex: 1 1 auto;
}

.detailsContainer {
  div {
    div:nth-child(1) {
      width: 180px;
      min-width: 180px;
    }
  }
}

.pdfButtons {
  display: flex;
  justify-content: space-between;
}

.pageNoSpan {
  margin-left: 1rem;
  margin-right: 1rem;
}

.minimumAmount {
  padding-left: 5px;
}

.viewDownloadLink {
  font-size: small;
}

.updateStatus {
  font-size: small;
  display: flex;
}

.setBillToPaidButton {
  font-size: small;
  margin: 0px;
  padding: 0 0 0 0.25rem;
}

.viewDownloadDiv {
  margin-bottom: 0px;
}

.textWarning{
  color: #8a6d3b;
}

.networkError{
  color: #8a6d3b;
  margin-bottom: 0px;
}

.paidTxt {
  margin-bottom: 0px;
}

.textDanger{
  color: #a94442;
}

.failedPayment{
  color: #a94442;
  margin-bottom: 0px;
}


.marginHeading {
  margin-top: 5px;
}

.learnMoreLink {
  margin-left: 0.5rem;
  font-size: small;
}

.payNowButton  {
  @media (max-width: 420px) {
    padding-top: 1.5rem;
  }

  @media (min-width: 421px) {
    padding-left: 180px;
  }
}

.payNowButtonText  {
  @media (max-width: 420px) {
    display: flex;
    width: 100%;

    > * {
      margin: 0 auto;
    }
  }
}

.archivedWarning {
  color: #8a6d3b;
  font-size: small;
  display: flex;
}
