.padHeading {
  padding-top: .5rem;
}

.formErrors {
  margin-top: 1rem;
  margin-bottom: 1rem;
  div {
    margin-right: 0px;
    margin-left: 0px;
  }
}

.images{
  width: 100%;
}

.buttonContainer {
  display: flex;
  margin-bottom: 1.5rem;
}

.newInputSection {
  margin-top: 3rem;
}
