@import "../../../payments/PaymentForm/payment-wizard-layout";

.paymentFormContainer {
  @include payment-wizard-container();
}

.paymentForm {
  @include payment-wizard-layout();

  .cardEntry {
    width: 100%;

    iframe {
      @include iframe-card-details();
    }
  }
}