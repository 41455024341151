@import "../../common/variables";

.container {
  width: 650px;
  margin: 2rem auto;

  @media (max-width: 650px) {
    width: 100%
  }
}

.backButtonContainer {
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;

}
