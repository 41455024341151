@import "../variables";

@mixin button($bg-color, $border-color, $color) {
  font-family: $font-headings;
  font-size: 1.6rem;
  border-width: 2px;
  background-color: $bg-color;
  color: $color;
  border-color: $border-color;

  &:hover, &:focus {
    background-color: $hover-primary-color;
    border-color: $hover-primary-color;
    color: $white;
    box-shadow: $box-shadow
  }
}

@mixin loader() {
  margin-left: 1rem;
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid lighten($primary-color, 30%);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1.5s linear infinite;
  margin-right: 0.5rem;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
