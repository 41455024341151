.logo {
  height: 100px;
}

.logoContainer {
  text-align: center;
}

.headerTop{
  width: 100%;
  height: 50px;
}

.container{
  display: flex;
  justify-content: center;

  @media (max-width: 700px) {
    display: inherit;
  }
}

.centerDiv {
  width: 700px;

  @media (max-width: 700px) {
    width: 100%
  }
}

.heading {
  text-align: center;
}

.resendContainer {
  margin-top: 1.5rem;
}

.resendHeading {
  font-weight: bold;
}

.verifyHelp {
  margin-top: 15px;
}

.alert {
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 1rem;
}