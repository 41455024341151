.nextButton {
  width: 100%;
  display: flex;
  justify-content: center;
}

.forgotPasswordContainer {
  margin-bottom: 1.5rem;
  margin-left: -10px;
  margin-top: -2rem;
}

.nextbuttonContainer {
  margin-bottom: 3rem;
}

.createAccountText  {
  margin-right: 0.5rem;
}

.alertContainer {
  margin: 0px;
}
