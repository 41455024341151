.container {
  display: flex;
  justify-content: center;
}

.centerDiv {
  width: 400px;

  @media (max-width: 400px) {
    width: 100%
  }
}