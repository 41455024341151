.nextButton {
  width: 100%;
  display: flex;
  justify-content: center;
}

.forgotPasswordContainer {
  margin-bottom: 15px;
  margin-left: -10px;
  margin-top: -25px;
}

.nextbuttonContainer {
  margin-bottom: 2.5rem;
}