.buttons{
  display: flex;
  justify-content: space-between;
}

.pageContainer {
  display: flex;

  @media (min-width: 1400px) {
    >:nth-child(1) {
      margin-right: 1rem;
    }

    >:nth-child(2) {
      margin-left: 1rem;
    }
  }

  @media (max-width: 1400px) {
    flex-direction: column;
  }
}

.resultDetailsContainer{
  flex: 1;
}