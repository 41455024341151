.invoiceContainer {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.markPaidCheckboxContainer {
  margin-bottom: 3rem;
}

.alertError {
  margin-top: 1rem;
}

.buttonContainer {
  display: flex;
}