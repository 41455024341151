.payreqLogoContainer {
  margin-top: 2.5rem;
  margin-bottom: 2rem;
}

.payreqLogo {
  height: 85px;
  padding-bottom: 15px;
  display: block;
  margin: 0 auto;
}