$search-input-width: 350px;

@mixin activeFilter() {
  >:nth-child(2) {
    border-color: #66afe9;
    border-width: 1px;
    background-color: #66afe917;
    outline: 0;
    box-shadow: 0 2px 4px 0 rgba(33, 33, 33, .2);
  }
}

@mixin searchContainer() {
  display: flex;

  > :first-child {
    width: $search-input-width;
    margin-right: 1rem;
    margin-bottom: 0;
  }
}

@mixin advancedSearch() {
  max-width: $search-input-width;
  margin-bottom: 1rem;
}

@mixin filterLabel($width) {
  min-width: $width;
  margin-right: 1rem;
}

@mixin advancedSearchField {
  display: flex;
  align-items: center;
  font-size: 1.4rem;
}
