.logo {
  height: 150px;
}

.logoContainer {
  @media (max-width: 700px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
