.cardIssuerSelection {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .cardIssuerSelect {
    margin-right: 1rem;
  }
}

.addCardIssuerBtn {
  padding-left: 0px;
  padding-top: 0px;
  margin-bottom: 1rem;
}

.acceptedCardsContainer {
  margin-top: 3.5rem;
  margin-bottom: 1rem;
}