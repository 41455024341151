$card-width: 800px;

.detailContainers{
  div{
    font-size: small;
    div:first-child{
      font-weight: bold;
      width: 120px;
      font-size: small;
    }
  }
}

.card {
  max-width: $card-width;
  margin-bottom: 2rem;

  >:first-child { //in bootstrap, there will be a panel-body as the first child

    .mainBody {
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      @media (max-width: 920px) {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

      }
    }
  }
}
