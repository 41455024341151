.formContainer {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.selectWrapper {
  margin: 0px 5px 5px 5px;
}

.secondaryHeadingContainer{
  margin-top: 2rem;
}