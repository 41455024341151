.reportTableContainer {
  table {
    tbody {
      tr {
        :first-child {
          width: 20%
        }
      }
    }
  }
}

.dateParams {
  display: flex;

  :first-child {
    margin-right: 1.5rem;
  }
}

.paramsContainer {
  width: max-content;
  margin-bottom: 2rem;
}
