.buttonContainer {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
}

.buttonWrapper {
  margin-right: 2rem;
}

.ruleWrapper {
  margin-top: 2rem;
}

.deleteHeader {
  margin-top: 2rem;
}