.newPassword {
  > :first-child {
    margin-bottom: 0.5em;
  }
}

.formInputsContainer {
  max-width: 400px;
}

.emailContainer {
 > div {
   display: flex;
   flex-direction: column;
   > :first-child {
     width: 16rem;
   }
 }
  margin: 1.5rem auto;
}

.resetPasswordContainer{
  width: 650px;
  margin: 2rem auto;

  @media (max-width: 650px) {
    width: 100%
  }
}

.errorAlert {
  margin: auto 0;
}
