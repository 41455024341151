@import "../../variables";
@import "../button";

// todo: should change to use bootstrap button classes once ready to test in all the places
.buttonIcon {
  margin-right: 0.5rem;
}

.button {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.noIconDecoration {
  &:hover {
    text-decoration: none;
  }

  span:nth-child(2):hover {
    color: #23527c;
    text-decoration: underline;
    background-color: transparent;
  }
}
