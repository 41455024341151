.newPassword {
  > :first-child {
    margin-bottom: 0.5em;
  }
}

.formInputsContainer {
  max-width: 400px;
}

.emailContainer {
 > div {
   display: flex;
   flex-direction: column;
 }
  margin: 1.5rem auto;
}

.card {
  max-width: 700px;
  margin: 2rem auto;
}

.errorAlert {
  margin: auto 0;
}