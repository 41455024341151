.cardRow {
  display: flex;
  justify-content: space-between;

  @media (max-width: 1200px) {
    flex-direction: column;
  }

  > div:nth-child(odd) {
    margin-right: 2rem;
  }
}