.logo {
  display: block;
  margin: 0 auto;
  height: 100px;
}

.headerTop{
  width: 100%;
  height: 50px;
}

.container{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.wrapper{
  width: 650px;
  @media (max-width: 650px) {
    width: 100%;
  }
}