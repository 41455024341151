$primary-color: #062A4B;
$secondary-color: #63ADF2;
$accent-color-one: #AFCFF8;
$accent-color-two: #E2E7ED;
$danger-color: #dc3545;
$warning-color: #8a6d3b;
$info-color: #31708f;
$success-color: #5cb85c;

$white: white;
$light-grey: #595959;
$errorColor: #a94442;
$hover-grey: #f5f5f5;
$body-text-color: #333333;
$hint-text-color: #737373;

//hover colours
$hover-primary-color: #062A4Bcc;

$card-entry-form-width: 450px; //This is simply a nice width for the iframe

$page-width: 1240px;

$card-width: 800px;

$box-shadow: 0 0.15em 0.5em 0 rgba(0, 0, 0, 0.25);
$box-shadow-hover: 0 0.15em 0.5em 0.25em rgba(0, 0, 0, 0.25);

$font-headings: 'ClashGrotesk-Medium', Arial, sans-serif;

@font-face {
  font-family: 'ClashGrotesk-Variable';
  src: url('../../resources/fonts/ClashGrotesk-Variable.woff2') format('woff2'),
  url('../../resources/fonts/ClashGrotesk-Variable.woff') format('woff'),
  url('../../resources/fonts/ClashGrotesk-Variable.ttf') format('truetype');
  font-weight: 200 700;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'ClashGrotesk-Extralight';
  src: url('../../resources/fonts/ClashGrotesk-Extralight.woff2') format('woff2'),
  url('../../resources/fonts/ClashGrotesk-Extralight.woff') format('woff'),
  url('../../resources/fonts/ClashGrotesk-Extralight.ttf') format('truetype');
  font-weight: 200;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'ClashGrotesk-Light';
  src: url('../../resources/fonts/ClashGrotesk-Light.woff2') format('woff2'),
  url('../../resources/fonts/ClashGrotesk-Light.woff') format('woff'),
  url('../../resources/fonts/ClashGrotesk-Light.ttf') format('truetype');
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'ClashGrotesk-Regular';
  src: url('../../resources/fonts/ClashGrotesk-Regular.woff2') format('woff2'),
  url('../../resources/fonts/ClashGrotesk-Regular.woff') format('woff'),
  url('../../resources/fonts/ClashGrotesk-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'ClashGrotesk-Medium';
  src: url('../../resources/fonts/ClashGrotesk-Medium.woff2') format('woff2'),
  url('../../resources/fonts/ClashGrotesk-Medium.woff') format('woff'),
  url('../../resources/fonts/ClashGrotesk-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'ClashGrotesk-Semibold';
  src: url('../../resources/fonts/ClashGrotesk-Semibold.woff2') format('woff2'),
  url('../../resources/fonts/ClashGrotesk-Semibold.woff') format('woff'),
  url('../../resources/fonts/ClashGrotesk-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}


@font-face {
  font-family: 'ClashGrotesk-Bold';
  src: url('../../resources/fonts/ClashGrotesk-Bold.woff2') format('woff2'),
  url('../../resources/fonts/ClashGrotesk-Bold.woff') format('woff'),
  url('../../resources/fonts/ClashGrotesk-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}
